import Vue from 'vue'
import VueRouter from "vue-router";

Vue.use(VueRouter)

const router = new VueRouter({
    routes: [
        {path: '/', component: require('@/views/Home.vue').default},
        {path: '/table', component: require('@/views/Table.vue').default},
        {path: '/maker', component: require('@/views/Maker.vue').default},
        {path: '/statistics', component: require('@/views/Statistics.vue').default},
        {path: '/announce', component: require('@/views/Preview.vue').default},
        {path: '/login', component: require('@/views/Login.vue').default},
        {path: '/register', component: require('@/views/Register.vue').default},
        {path: '/settings', component: require('@/views/UserSetting.vue').default},
        {path: '/admin', component: require('@/views/Admin.vue').default},
        {path: '/game/:id', component: require('@/views/Game.vue').default},
        {path: '/404', component: require('@/views/NotFound.vue').default},
        {path: '*', redirect: '/404'}
    ]
})

export default router
