<script>

import axios from "axios";

export default {
  name: 'BgTag',
  data() {
    return {
      tag: '',
      bgList: []
    }
  },
  methods: {
    getBgTagList() {
      axios.get('/api/admin/bg', {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        this.bgList = res.data.data
      })
    },
    addBgTag(tag) {
      if (tag === '') {
        this.$notify.error({
          title: '添加失败',
          message: '标签名不能为空'
        })
        return
      }

      axios.post('/api/admin/bg', {
        'name': tag
      }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '添加成功',
            message: '背景标签已添加'
          })
          this.getBgTagList()
        } else {
          this.$notify.error({
            title: '添加失败',
            message: res.data.message
          })
        }
        this.tag = ''
      })
    },
    removeBgTag(id) {
      if (this.bgList.length === 1) {
        this.$notify.error({
          title: '删除失败',
          message: '至少保留一个背景标签'
        })
        return
      }

      axios.delete('/api/admin/bg', {
        data: {
          'id': id
        },
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '删除成功',
            message: '背景标签已删除'
          })
          this.getBgTagList()
        } else {
          this.$notify.error({
            title: '删除失败',
            message: res.data.message
          })
        }
      })
    },
    toggleBgTagStatus(id) {
      if (this.bgList.filter(item => item.enable).length === 1 && this.bgList.find(item => item.id === id).enable) {
        this.$notify.error({
          title: '修改失败',
          message: '至少保留一个启用的背景标签'
        })
        return
      }

      axios.put('/api/admin/bg', {
        'id': id
      }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '修改成功',
            message: '背景标签状态已修改'
          })
          this.getBgTagList()
        } else {
          this.$notify.error({
            title: '修改失败',
            message: res.data.message
          })
        }
      })
    }
  },
  mounted() {
    this.getBgTagList()

    let hr = document.querySelector('hr')
    let tagList = document.querySelector('#tag-list')
    tagList.style.width = hr.style.width + 'px'
  }
}
</script>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6">&nbsp;</el-col>
      <el-col :span="10">
        <el-input v-model="tag" placeholder="请输入标签名"></el-input>
      </el-col>
      <el-col :span="2">
        <el-button type="primary" @click="addBgTag(tag)">添加标签</el-button>
      </el-col>
      <el-col :span="6">&nbsp;</el-col>
    </el-row>
    <hr>
    <div id="tag-list">
      <el-tag
          v-for="item in bgList"
          :key="item.id"
          :closable="true"
          :type="item.enable ? 'success' : 'danger'"
          @close="removeBgTag(item.id)"
          @click="toggleBgTagStatus(item.id)">
        <span v-if="item.enable">{{ item.name }}</span>
        <span v-else><del>{{ item.name }}</del></span>
      </el-tag>
    </div>
  </div>
</template>

<style scoped>
#tag-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.el-tag {
  border-radius: 50px;
  margin: 5px;
}

.el-row {
  margin-top: 20px;
}

hr {
  color: #909399;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
