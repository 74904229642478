<script>
import axios from "axios";

export default {
  name: 'GameInfo',
  data() {
    return {
      gameId: '',
      token: ''
    }
  },
  mounted() {
    this.gameId = this.$route.params.id
    // 游戏id不存在或不是数字
    if (this.gameId === null || this.gameId === '' || this.gameId === undefined || isNaN(this.gameId)) {
      this.$router.push('/404')
    }

    let token = localStorage.getItem('token');
    if (token === null) {
      return
    }
    axios.post('/api/getRole', {}, {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(res => {
      let data = res.data.data;

      if (data.id === null) {
        return
      }

      this.token = token
    })
  }
}
</script>

<template>
 <div>
   游戏详情
 </div>
</template>

<style scoped>

</style>
