<script>
import axios from "axios";
import {EventBus} from "@/EventBus";

export default {
  name: 'UserLogin',
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post("/api/login", this.form).then(res => {
            if (res.data.code === 200) {
              this.$notify.success({
                'title': '登录成功',
                'message': `欢迎回来, 3秒后跳转到登录前页面`
              })
              // 保存jwt
              let token = res.data.data.token
              localStorage.setItem('token', token)
              EventBus.$emit('changeState')
              // 登录前的页面
              let beforeLogin = localStorage.getItem('beforeLogin');
              // 清空登录前的页面
              localStorage.removeItem('beforeLogin')
              if (beforeLogin === null || beforeLogin === '/login' || beforeLogin === 'null' || beforeLogin === '/404') {
                beforeLogin = '/'
              }
              setTimeout(() => {
                this.$router.push(beforeLogin)
              }, 3000)
            } else {
              this.$notify.error({
                'title': '登录失败',
                'message': res.data.message
              })
            }
          })
        } else {
          return false;
        }
      })
    },
    register() {
      this.$router.push('/register')
    }
  },
  mounted() {
    // 如果已经登录，跳转到登录前的页面
    if (localStorage.getItem('token') !== null) {
      this.$router.push('/')
    }

    // 设置body高度
    let innerHeight = window.innerHeight;
    this.$refs.body.style.height = innerHeight - 61 - 20 - 20 - 40 + 'px';
  }
}
</script>

<template>
  <div ref="body">
    <el-row>
      <el-col :xs="0" :sm="2" :md="4" :lg="6" :xl="8">&nbsp;</el-col>
      <el-col :xs="24" :sm="20" :md="16" :lg="12" :xl="8" class="login-col">
        <div class="login-form">
          <h1 class="login-title">登录</h1>
          <el-form :model="form" :rules="rules" ref="form" label-width="75px">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="form.username" auto-complete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                  type="password"
                  v-model="form.password"
                  auto-complete="off"
                  @keyup.enter.native="submitForm('form')"
              ></el-input>
            </el-form-item>
          </el-form>
          <div style="display: flex; justify-content: flex-end">
            <el-button type="text" @click="register">注册</el-button>
            <el-button type="primary" @click="submitForm('form')">登录</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  height: 100%;
  display: flex;
  align-items: center;
}

.login-form {
  border-radius: 20px;
  padding: 10px;
  width: 93%;
}

.login-title {
  text-align: center;
  margin-bottom: 20px;
}
</style>
