<script>
import {EventBus} from "@/EventBus";
import axios from "axios";

export default {
  name: 'GamePreviewCard',
  props: ['game'],
  data() {
    return {
      info: {
        id: '',
        imageUrl: '',
        gameType: '',
        gameUrl: '',
        name: '',
        gameId: '',
        makerId: '',
        makerUrl: '',
        makerName: '',
        genre: [],
        price: '',
        tags: [],
        ciEnUrl: '',
        ciEnAvatar: '',
        follow: false
      },
      login: false,
      ageFlag: false,
      ageTag: ''
    }
  },
  beforeMount() {
    this.login = localStorage.getItem('token') !== null
  },
  mounted() {
    EventBus.$on('changeState', () => {
      this.login = localStorage.getItem('token') !== null
    })
    this.info = this.game
    this.info.imageUrl = 'https:' + this.info.imageUrl

    if (this.info.genre.length === 1 && this.info.genre[0] === '') {
      this.info.genre = []
    }

    if (this.info.tags.length === 1 && this.info.tags[0] === '') {
      this.info.tags = []
    }

    this.info.genre.forEach((genre) => {
      if (genre === '全年齢') {
        this.ageFlag = true;
        this.ageTag = '全年齢'
      } else if (genre === 'R-15') {
        this.ageFlag = true;
        this.ageTag = 'R-15'
      }
    })
  },
  methods: {
    addFollow(id) {
      axios.post('/api/user/announce', {
        'id': id
      }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '收藏成功',
            message: '游戏已加入收藏'
          })
          this.info.follow = true
        } else if (res.data.code === 400) {
          this.$notify.warning({
            title: '你已经收藏过了',
            message: '请勿重复收藏'
          })
        } else if (res.data.code === 500) {
          this.$notify.error({
            title: '收藏失败',
            message: res.data.message
          })
        }
      })
    },
    removeFollow(id) {
      axios.delete('/api/user/announce', {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        },
        data: {
          'id': id
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '取消成功',
            message: '游戏已取消收藏'
          })
          this.info.follow = false
        } else if (res.data.code === 400) {
          this.$notify.warning({
            title: '你还没有收藏',
            message: '请勿重复取消'
          })
        } else if (res.data.code === 500) {
          this.$notify.error({
            title: '取消失败',
            message: res.data.message
          })
        }
      })
    },
    addBlacklist(id) {
      axios.post('/api/user/author/blacklist', {
        'id': id
      }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '加入黑名单成功',
            message: '已将该作者加入黑名单'
          })
          EventBus.$emit('refreshPreviewList')
        } else if (res.data.code === 400) {
          this.$notify.warning({
            title: '加入黑名单失败',
            message: res.data.message
          })
        } else if (res.data.code === 500) {
          this.$notify.error({
            title: '加入黑名单失败',
            message: res.data.message
          })
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <el-card class="card">
      <div style="display: flex; align-content: flex-start">
        <div>
          <el-image :src="info.imageUrl" style="width: 300px">
          </el-image>
        </div>
        <div style="padding-left: 25px">
          <h3 class="game-id">
            <span>{{ info.gameId }}</span>
            <el-tag effect="plain" size="small" style="margin-left: 5px">{{ info.gameType }}</el-tag>
            <div v-if="login">
              <el-button v-if="info.follow" size="small" type="danger" plain style="margin-left: 5px"
                         @click="removeFollow(info.id)">取消收藏
              </el-button>
              <el-button v-else size="small" type="success" plain style="margin-left: 5px" @click="addFollow(info.id)">
                加入收藏
              </el-button>
            </div>
          </h3>
          <h3 class="game-name">
            <a :href="info.gameUrl" target="_blank">{{ info.name }}</a>
            <el-tag size="small" v-if="ageFlag" type="danger" style="margin-left: 5px">{{ ageTag }}</el-tag>
          </h3>
          <h4 class="maker" style="display: flex; align-content: center">
            <a :href="info.makerUrl" target="_blank">{{ info.makerName }}</a>
            <span v-if="info.ciEnUrl">
              <el-divider direction="vertical"></el-divider>
              <el-avatar :src="info.ciEnAvatar" style="height: 16px; width: 16px"></el-avatar>
              <a :href="info.ciEnUrl" target="_blank"> Ci-en</a>
            </span>
            <el-link type="danger" style="margin-left: 10px" :underline="false" @click="addBlacklist(info.makerId)"
                     v-if="login">
              <font-awesome-icon icon="fa-solid fa-ban"/>
              加入黑名单
            </el-link>
          </h4>
          <div v-if="info.genre.length !== 0">
            <div v-for="genre in info.genre" :key="genre" style="display: inline-block" class="genre">
              <el-tag size="mini" style="margin-right: 5px" type="warning">{{ genre }}</el-tag>
            </div>
          </div>
          <div v-else style="height: 34.766px"></div>
          <div style="color: #F56C6C; font-weight: bold; font-size: 16px" class="price">{{ info.price }}</div>
          <div v-if="info.tags.length !== 0">
          <div v-for="tag in info.tags" :key="tag" style="display: inline-block" class="tag">
            <el-tag size="small" style="margin-right: 5px">{{ tag }}</el-tag>
          </div>
          </div>
          <div v-else style="height: 29.77px;"></div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<style scoped>
.card {
  padding-right: 20px;
}

.game-name {
  margin: 0;
}

.game-id {
  margin-top: 10px;
  margin-bottom: 0;
  display: flex;
  align-content: flex-start;
}

.maker {
  margin-top: 10px;
  margin-bottom: 20px;
}

.genre {
  margin-top: 10px;
}

.price {
  margin-top: 5px;
}

.tag {
  margin-top: 5px;
}

a {
  color: #409EFF;
  text-decoration: none;
}
</style>
