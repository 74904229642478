import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import Nav from './components/Nav.vue'
import router from './router/index'
import * as echarts from 'echarts'
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
    faHouse,
    faBlog,
    faBan,
    faEarthAmericas,
    faXmark,
    faArrowTurnUp,
    faArrowTurnDown,
    faGamepad,
    faTrashCan,
    faCircleUser,
    faShopSlash,
    faLinkSlash
} from "@fortawesome/free-solid-svg-icons";
import {faCalendar, faCreditCard, faHeart} from "@fortawesome/free-regular-svg-icons";
import {faQq} from "@fortawesome/free-brands-svg-icons";
import Waterfall from "waterfall-vue2";

library.add(faHouse)
library.add(faBlog)
library.add(faBan)
library.add(faEarthAmericas)
library.add(faXmark)
library.add(faArrowTurnUp)
library.add(faArrowTurnDown)
library.add(faGamepad)
library.add(faCalendar)
library.add(faCreditCard)
library.add(faHeart)
library.add(faTrashCan)
library.add(faQq)
library.add(faCircleUser)
library.add(faShopSlash)
library.add(faLinkSlash)

Vue.use(ElementUI)
Vue.use(Waterfall)
Vue.prototype.$echarts = echarts
Vue.component('GlobalNav', Nav)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false


new Vue({
    render: h => h(App),
    router: router
}).$mount('#app')
