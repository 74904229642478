<script>
import axios from "axios";

export default {
  name: 'UserCard',
  props: ['user', 'role', 'disable'],
  data() {
    return {
      info: {
        id: '',
        createTime: '',
        qqNumber: '',
        role: '',
        username: '',
        avatar: ''
      },
      imageUrl: '',
      userRole: '',
      defaultRole: ''
    }
  },
  mounted() {
    this.info = this.user
    this.defaultRole = this.info.role

    if (this.info.username === null) {
      this.info.username = '未注册用户'
    }

    axios.get('/api/admin/user/role', {
      headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')}
    }).then(res => {
      if (res.data.code === 200) {
        this.userRole = res.data.data
      }
    })

    if (this.info.avatar !== null) {
      axios.get(`/api/admin/user/avatar?fileName=${this.info.avatar}`, {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
        responseType: 'blob'
      }).then(res => {
        let fileType = res.headers['content-disposition'].split('.')[1]
        let blob
        if (fileType === 'jpeg' || fileType === 'jpg') {
          blob = new Blob([res.data], {type: 'image/jpeg'})
        } else {
          blob = new Blob([res.data], {type: 'image/png'})
        }
        this.imageUrl = URL.createObjectURL(blob)

        // 调整头像大小
        setTimeout(() => {
          let avatarDiv = document.querySelector('#avatar-div');
          let width = avatarDiv.clientWidth * 80 / 100
          let avatar = document.querySelector('#avatar');
          avatar.style.width = width + 'px'
          avatar.style.height = width + 'px'
        }, 100)
      })
    }
  },
  methods: {
    updateRole(id) {
      console.log(this.info.role, this.user.role)
      if (this.info.role !== this.defaultRole) {
        axios.post('/api/admin/user/role', {
          userId: id,
          roleId: this.info.role
        }, {
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }).then(res => {
          if(res.data.code === 200) {
            this.defaultRole = this.info.role
            this.$notify.success({
              title: '修改成功',
              message: '用户权限已更新'
            })
          }
        })
      }
    }
  }
}
</script>

<template>
  <div>
    <el-card shadow="hover" class="card">
      <el-row>
        <el-col :span="6" id="avatar-div">
          <el-avatar :src="imageUrl" shape="square" fit="" id="avatar"/>
        </el-col>
        <el-col :span="18">
          <h4>{{ this.info.username }}</h4>
          <div class="info-row">
            <font-awesome-icon icon="fa-brands fa-qq" style="color: #409EFF"/> 注册QQ
            {{ this.info.qqNumber }}
          </div>
          <div class="info-row">
            <font-awesome-icon icon="fa-regular fa-calendar"/> 注册日期
            {{ this.info.createTime }}
          </div>
          <div class="info-row">
            <font-awesome-icon icon="fa-solid fa-circle-user"/> 权限组
            <el-select
                v-model="info.role"
                size="mini"
                :disabled="info.role === disable || userRole !== disable"
                @change="updateRole(info.id)"
            >
              <el-option
                v-for="r in role"
                :key="r.value"
                :label="r.label"
                :value="r.value"
                :disabled="r.value === disable"
              />
            </el-select>
          </div>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<style scoped>
.card {
  border-radius: 10px;
  margin-bottom: 20px;
}

h4 {
  margin-top: 0;
}

.info-row {
  margin-bottom: 10px;
}
</style>
