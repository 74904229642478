<script>
import UserInfo from "@/components/UserInfo.vue";
import UserBackground from "@/components/UserBackground.vue";
import UserAnnounce from "@/components/UserAnnounce.vue";
import AuthorBlacklist from "@/components/Blacklist.vue";

export default {
  name: 'UserSetting',
  components: {UserInfo, UserBackground, UserAnnounce, AuthorBlacklist},
  data() {
    return {
      showUserInfo: false,
      showChangeAvatar: false,
      showBackgroundSetting: false,
      showAnnounceFollow: false,
      showBlacklist: false,
      collapse: false,
      backgroundCol: 3
    }
  },
  created() {
    if (localStorage.getItem('token') === null) {
      this.$notify.error({
        title: '未登录',
        message: '请先登录'
      })
      this.$router.push('/login')
      return
    }
    setTimeout(() => {
      this.showUserInfo = true
    }, 100)
  },
  methods: {
    selectMenu(index) {
      switch (index) {
        case 'user-info':
          this.showUserInfo = true
          this.showChangeAvatar = false
          this.showBackgroundSetting = false
          this.showAnnounceFollow = false
          this.showBlacklist = false
          break
        case 'background-setting':
          this.showUserInfo = false
          this.showChangeAvatar = false
          this.showBackgroundSetting = true
          this.showAnnounceFollow = false
          this.showBlacklist = false
          break
        case 'announce-follow':
          this.showUserInfo = false
          this.showChangeAvatar = false
          this.showBackgroundSetting = false
          this.showAnnounceFollow = true
          this.showBlacklist = false
          break
        case 'blacklist':
          this.showUserInfo = false
          this.showChangeAvatar = false
          this.showBackgroundSetting = false
          this.showAnnounceFollow = false
          this.showBlacklist = true
          break
      }
    },
    onresize() {
      // 浏览器宽度
      let innerWidth = window.innerWidth;
      if (innerWidth < 768) {
        this.collapse = true
        this.backgroundCol = 1
      } else if (innerWidth < 992) {
        this.collapse = false
        this.backgroundCol = 1
      } else if (innerWidth < 1920) {
        this.collapse = false
        this.backgroundCol = 2
      } else {
        this.collapse = false
        this.backgroundCol = 3
      }
    }
  },
  mounted() {
    this.onresize()
    addEventListener('resize', this.onresize)
  },
  beforeDestroy() {
    removeEventListener('resize', this.onresize)
  }
}
</script>

<template>
  <div ref="body">
    <div v-if="collapse">
      <el-menu @select="selectMenu" default-active="user-info">
        <el-menu-item index="user-info">个人资料</el-menu-item>
        <el-menu-item index="background-setting">背景设置</el-menu-item>
        <el-menu-item index="announce-follow">预告收藏</el-menu-item>
        <el-menu-item index="blacklist">黑名单</el-menu-item>
      </el-menu>
      <div style="overflow: scroll">
        <UserInfo v-show="showUserInfo"></UserInfo>
        <UserBackground v-show="showBackgroundSetting" :col="backgroundCol"></UserBackground>
        <UserAnnounce v-show="showAnnounceFollow"></UserAnnounce>
        <AuthorBlacklist v-show="showBlacklist"></AuthorBlacklist>
      </div>
    </div>
    <div v-else>
      <el-container>
        <el-aside style="padding: 20px 0">
          <el-menu @select="selectMenu" default-active="user-info">
            <el-menu-item index="user-info">个人资料</el-menu-item>
            <el-menu-item index="background-setting">背景设置</el-menu-item>
            <el-menu-item index="announce-follow">预告收藏</el-menu-item>
            <el-menu-item index="blacklist">黑名单</el-menu-item>
          </el-menu>
        </el-aside>
        <el-main style="border-left: 1px; margin-left: 10px">
          <UserInfo v-show="showUserInfo"></UserInfo>
          <UserBackground v-show="showBackgroundSetting" :col="backgroundCol"></UserBackground>
          <UserAnnounce v-show="showAnnounceFollow"></UserAnnounce>
          <AuthorBlacklist v-show="showBlacklist"></AuthorBlacklist>
        </el-main>
      </el-container>
    </div>
  </div>
</template>

<style scoped>

</style>
