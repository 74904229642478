<script>
import axios from "axios";

export default {
  name: 'UserAnnounce',
  data() {
    return {
      pageSize: 25,
      totalPage: 1,
      current: 1,
      paginationPager: 5,
      unSaleOnly: true,
      rows: []
    }
  },
  mounted() {
    this.getAnnounceData(1)
  },
  methods: {
    getAnnounceData(pageNo) {
      axios.get('/api/user/announce', {
        params: {
          pageNo: pageNo,
          pageSize: this.pageSize,
          unSaleOnly: this.unSaleOnly
        },
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        let data = res.data.data;
        this.totalPage = data.pageCount
        this.current = data.current
        this.rows = data.rows
      })
    },
    removeFollow(id) {
      axios.delete('/api/user/setting/announce', {
        data: {
          id: id
        },
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '操作成功',
            message: '取消收藏成功'
          })
          this.getAnnounceData(1)
        } else {
          this.$notify.error({
            title: '操作失败',
            message: res.data.message
          })
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <el-checkbox v-model="unSaleOnly" label="只看未发售游戏" border id="unSaleOnly" @change="getAnnounceData(1)"></el-checkbox>
    <el-table
        ref="table"
        :data="rows"
        style="width: 100%;"
        show-header
    >
      <el-table-column
          prop="gameId"
          label="游戏ID"
          width="120">
        <template slot-scope="scope">
          <el-popover trigger="hover" placement="left">
            <el-image
                :src="scope.row.cover"
                fit="contain"
                style="width: 250px; height: 188px"
            >
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline"></i>
              </div>
              <div slot="placeholder">
                <i class="el-icon-loading"></i>
              </div>
            </el-image>
            <div slot="reference">{{ scope.row.gameId }}</div>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
          label="已发售"
          width="100"
      >
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isSale" type="info">已发售</el-tag>
          <el-tag v-else type="warning">未发售</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="gameName"
          label="游戏名"
          width="300"
      >
        <template slot-scope="scope">
          <a :href="scope.row.gameUrl" target="_blank">{{ scope.row.gameName }}</a>
        </template>
      </el-table-column>
      <el-table-column
          prop="authorName"
          label="社团名"
          width="200"
      >
        <template slot-scope="scope">
          <a :href="scope.row.authorUrl" target="_blank">{{ scope.row.authorName }}</a>
        </template>
      </el-table-column>
      <el-table-column
          prop="saleDate"
          label="预计发售日期"
          width="150"
      ></el-table-column>
      <el-table-column
          prop="price"
          label="售价"
      ></el-table-column>
      <el-table-column
          label="标签"
          width="500"
      >
        <template slot-scope="scope">
          <el-tag v-for="tag in scope.row.tag.split(' ')" :key="tag" size="mini" style="margin-right: 5px">{{
              tag
            }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="removeFollow(scope.row.id)">取消收藏</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="foot">
      <div class="foot-div">
        <el-form :inline="true" size="mini">
          <el-form-item label="每页显示">
            <el-select v-model="pageSize" class="orderBySelect" @change="getAnnounceData(1)">
              <el-option label="25" value="25"></el-option>
              <el-option label="50" value="50"></el-option>
              <el-option label="75" value="75"></el-option>
              <el-option label="100" value="100"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div class="foot-div-pagination">
        <el-pagination
            class="pagination"
            background
            layout="prev, pager, next"
            :page-count="totalPage"
            :pager-count="paginationPager"
            :current-page="current"
            @current-change="getAnnounceData($event)"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>

<style scoped>
a {
  color: #606266;
  text-decoration: none;
}

.foot {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

#unSaleOnly {
  margin-bottom: 10px;
}
</style>
