<script>
import axios from "axios";

export default {
  name: "UserRegister",
  data() {
    return {
      form: {
        username: '',
        password: '',
        checkPass: '',
        qq: ''
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'},
          {min: 3, max: 20, message: '长度在 3 到 20 个字符', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {min: 8, max: 20, message: '长度在 8 到 20 个字符', trigger: 'blur'}
        ],
        checkPass: [
          {required: true, message: '请再次输入密码', trigger: 'blur'},
          {
            validator: (rule, value, callback) => {
              if (value === '') {
                callback(new Error('请再次输入密码'))
              } else if (value !== this.form.password) {
                callback(new Error('两次输入密码不一致!'))
              } else {
                callback()
              }
            }, trigger: 'blur'
          }
        ],
        qq: [
          {required: true, message: '请输入qq号', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    submitForm() {
      let data = {
        username: this.form.username,
        password: this.form.password,
        qq: this.form.qq
      }

      axios.post('/api/register', data).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '注册成功',
            message: '注册成功~'
          })
          this.$router.push('/login')
        } else {
          this.$notify.error({
            title: '注册失败',
            message: res.data.message
          })
        }
      })
    }
  },
  mounted() {
    // 如果已经登录，跳转到登录前的页面
    if (localStorage.getItem('token') !== null) {
      this.$router.push('/')
    }

    // 设置body高度
    let innerHeight = window.innerHeight;
    this.$refs.body.style.height = innerHeight - 61 - 20 - 20 - 40 + 'px';
  }
}
</script>

<template>
  <div ref="body">
    <el-row>
      <el-col :xs="0" :sm="2" :md="4" :lg="6" :xl="8">&nbsp;</el-col>
      <el-col :xs="24" :sm="20" :md="16" :lg="12" :xl="8" class="register-col">
        <div class="register-form">
          <h1 class="register-title">用户注册</h1>
          <el-form ref="form" :model="form" :rules="rules" label-width="100px">
            <el-form-item label="用户名" prop="username">
              <el-input v-model="form.username"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input type="password" v-model="form.password"></el-input>
            </el-form-item>
            <el-form-item label="确认密码" prop="checkPass">
              <el-input type="password" v-model="form.checkPass"></el-input>
            </el-form-item>
            <el-form-item label="请输入qq号" prop="qq">
              <el-input v-model="form.qq"></el-input>
            </el-form-item>
          </el-form>
          <div style="display: flex; justify-content: flex-end">
            <el-button type="primary" @click="submitForm">注册</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.el-row {
  height: 100%;
  display: flex;
  align-items: center;
}

.register-title {
  text-align: center;
  margin-bottom: 20px;
}

.register-form {
  padding: 10px;
  width: 93%;
}
</style>
