<script>
import UserCardPanel from "@/components/UserCardPanel.vue";
import BgTag from "@/components/BgTag.vue";

export default {
  name: 'AdminPanel',
  components: {BgTag, UserCardPanel},
  data() {
    return {
      showUser: true,
      showCreator: false,
      bgTag: false
    }
  },
  methods: {
    changeMenu(index) {
      switch (index) {
        case 'user':
          this.showUser = true;
          this.showCreator = false;
          this.bgTag = false;
          break
        case 'creator':
          this.showUser = false;
          this.showCreator = true;
          this.bgTag = false;
          break
        case 'bgTag':
          this.showUser = false;
          this.showCreator = false;
          this.bgTag = true;
          break
      }
    }
  },
  mounted() {
    let clientHeight = window.innerHeight;
    let back = document.querySelector('#back');
    let backHeight = clientHeight - 60 - 20 - 10 - 10 - 20 - 20;
    back.style.height = backHeight + 'px';
  }
}
</script>

<template>
  <div id="back">
    <el-menu @select="changeMenu" default-active="user" mode="horizontal">
      <el-menu-item index="user">用户管理</el-menu-item>
      <el-menu-item index="creator">社团管理</el-menu-item>
      <el-menu-item index="bgTag">背景tag管理</el-menu-item>
    </el-menu>
    <div>
      <user-card-panel v-show="showUser"></user-card-panel>
      <bg-tag v-show="bgTag"></bg-tag>
    </div>
  </div>
</template>

<style scoped>
.el-menu {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}

.el-menu-item {
  color: #606266;
}

.is-active {
  color: #303133;
}
</style>
