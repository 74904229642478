<script>
export default {
  name: 'MakerGameItem',
  props: ['game'],
  data() {
    return {
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      info: this.game,
      imgSpan: 0,
      infoSpan: 0,
      infoBoxName: '',
    }
  },
  mounted() {
    this.resize()
    window.addEventListener('resize', this.resize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    changeSpan(level) {
      this.infoBoxName = 'info-box-' + level
      if (level === 1 || level === 2) {
        this.imgSpan = 24
        this.infoSpan = 24
      } else if (level === 3) {
        this.imgSpan = 11
        this.infoSpan = 13
      } else if (level === 4) {
        this.imgSpan = 10
        this.infoSpan = 14
      } else {
        this.imgSpan = 8
        this.infoSpan = 16
      }
    },
    resize() {
      let width = document.documentElement.clientWidth
      let level = 0
      if (width < 768) {
        level = 1
      } else if (width < 992) {
        level = 2
      } else if (width < 1200) {
        level = 3
      } else if (width < 1920) {
        level = 4
      } else {
        level = 5
      }
      this.changeSpan(level)
    }
  }
}
</script>

<template>
  <div>
    <el-row>
      <el-col class="img-box" :span="imgSpan">
        <el-carousel indicator-position="none" :interval="5000">
          <el-carousel-item v-for="item in info.covers" :key="item">
            <img :src="item" alt="cover" style="width: 100%; aspect-ratio: 4 / 3">
          </el-carousel-item>
        </el-carousel>
      </el-col>
      <el-col class="info-box" :class="infoBoxName" :span="infoSpan">
        <h3>
          <el-tag>{{ game.id }}</el-tag>
          <span class="game-id">&nbsp;&nbsp;{{ game.gameId }}</span>
        </h3>
        <div class="game-info">
          <h4>
            <a :href="game.dlsiteUrl" class="dlsiteUrl" v-if="!game.hasDelete" target="_blank">{{ game.name }}&nbsp;</a>
            <span v-else>{{ game.name }}&nbsp;</span>
            <font-awesome-icon icon="fa-solid fa-ban" style="color: #F56C6C" v-if="game.hasDelete"/>
            <el-tag size="small" type="warning" class="tag">{{ game.language }}</el-tag>
            <el-tag size="small" type="success" class="tag">{{ game.type.split('/')[0] }}</el-tag>
          </h4>
          <p>
            <font-awesome-icon icon="fa-solid fa-earth-americas"/>
            译名:
            <span v-if="game.translatedName">{{ game.translatedName }}</span>
            <font-awesome-icon icon="fa-solid fa-xmark" v-else/>
          </p>
          <p>
            <font-awesome-icon icon="fa-regular fa-calendar"/>
            发售日: {{ game.releaseTime }}
          </p>
          <p>
            <font-awesome-icon icon="fa-regular fa-credit-card"/>
            <span style="color: #F56C6C; font-weight: bold"> {{
                game.price
              }} 円</span>
            <el-divider direction="vertical"></el-divider>
            <el-rate
                v-model="this.info.score"
                disabled
                show-score
                text-color="#FF9900"
                style="display: inline-block"
                :colors="colors"
            ></el-rate>
            <el-divider direction="vertical"></el-divider>
            <font-awesome-icon icon="fa-solid fa-gamepad"/>
            <span style="color: #67C23A; font-weight: bold"> {{
                game.saleCount
              }}</span>
          </p>
          <el-tag size="small" v-for="tag in game.tags" :key="tag" class="game-tag">{{ tag }}</el-tag>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped lang="scss">
.img-box {
  display: inline-block;
}

.info-box {
  display: inline-block;
  padding-left: 10px;
}

h3 {
  margin-top: 0;
  display: flex;
  align-content: center;
}

.game-id {
  line-height: 32px;
  height: 32px;
}

h4 > a:link, h4 > a:visited {
  color: #211C6A;
}

.dlsiteUrl {
  text-decoration: none;
}

.game-tag {
  margin-right: 5px;
}

.game-info {
  padding-left: 40px;
}

.info-box-1 {
  margin-top: 20px;

  .tag {
    margin-left: 5px;
  }
}

.info-box-2 {
  margin-top: 10px;

  h3 {
    margin-bottom: 0;
  }

  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .tag {
    margin-left: 5px;
  }

  p {
    margin: 5px;
  }
}

.info-box-3 {
  h3 {
    margin-bottom: 0;
  }

  h4 {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  p {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .tag {
    margin-left: 5px;
  }
}

.info-box-4 {
  .tag{
    margin-left: 10px;
  }
}

.info-box-5 {
  h3 {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  h4 {
    margin-top: 10px;
  }

  .tag {
    margin-left: 10px;
  }
}
</style>