<script>
import axios from "axios";
import UserCard from "@/components/UserCard.vue";

export default {
  name: 'UserCardPanel',
  components: {UserCard},
  data() {
    return {
      userInfo: [],
      gameChunk: [],
      userRole: [],
      disable: ''
    }
  },
  mounted() {
    let token = localStorage.getItem('token');
    if (token === null) {
      this.$notify.error({
        title: '未登录',
        message: '请先登录'
      })
      this.$router.push('/login')
      return
    }
    axios.get('/api/admin/user', {
      headers: {
        'Authorization': 'Bearer ' + token
      }
    }).then(res => {
      if (res.data.code === 400) {
        this.$notify.warning({
          title: '',
          message: 'res.data.message'
        })
      } else if (res.data.code === 500) {
        this.$notify.error({
          title: '',
          message: 'res.data.message'
        })
      } else if (res.data.code === 200) {
        axios.get('/api/admin/select/role', {
          headers: {'Authorization': 'Bearer ' + token}
        }).then(res => {
          if (res.data.code === 200) {
            this.userRole = res.data.data.roles
            this.disable = res.data.data.disable
          }
        })
        this.userInfo = res.data.data
        this.getRows()
      }
    })
  },
  methods: {
    getRows() {
      this.gameChunk = []
      for (let i = 0; i < this.userInfo.length; i += 3) {
        this.gameChunk.push(this.userInfo.slice(i, i + 3))
      }
    }
  }
}
</script>

<template>
  <div class="panel">
    <el-row :gutter="20" v-for="(chunk, index) in gameChunk" :key="index">
      <el-col
        v-for="user in chunk"
        :key="user.id"
        :span="8">
        <user-card :user="user" :role="userRole" :disable="disable"></user-card>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.panel {
  margin: 20px;
}
</style>
