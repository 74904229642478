<script>
import axios from "axios";

export default {
  name: 'AuthorBlacklist',
  data() {
    return {
      pageSize: 25,
      totalPage: 1,
      current: 1,
      paginationPager: 5,
      rows: []
    }
  },
  mounted() {
    this.getUserBlacklist(1)
  },
  methods: {
    getUserBlacklist(pageNo) {
      axios.get('/api/user/author/blacklist', {
        params: {
          pageNo: pageNo,
          pageSize: this.pageSize
        },
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        let data = res.data.data
        this.totalPage = data.pageCount
        this.current = data.current
        this.rows = data.rows
      })
    },
    removeBlacklist(id) {
      axios.delete('/api/user/author/blacklist', {
        data: {
          id: id
        },
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '操作成功',
            message: '移除黑名单成功'
          })
          this.getUserBlacklist(1)
        } else {
          this.$notify.error({
            title: '操作失败',
            message: res.data.message
          })
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <p id="helper">黑名单用于过滤预告中不想看到的作者</p>
    <el-table
        ref="table"
        :data="rows"
        show-header>
      <el-table-column
          prop="authorId"
          label="社团id"
          width="150"
      />
      <el-table-column
          label="社团名">
        <template slot-scope="scope">
          <div id="makerName">
            <a :href="scope.row.dlsiteUrl" target="_blank">{{ scope.row.name }}</a>
            <el-avatar :src="scope.row.avatar" style="width: 32px; height: 32px" v-if="scope.row.avatar"></el-avatar>
          </div>
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="removeBlacklist(scope.row.id)">移除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<style scoped>
#helper {
  color: #606266;
  font-style: italic;
}

#makerName {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
