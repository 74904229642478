<template>
  <div class="root">
    <el-container>
      <el-header>
        <el-row>
          <el-col
              :xs="{span:24, offset:0}"
              :sm="{span:22, offset:1}"
              :md="{span:20, offset:2}"
              :lg="{span:18, offset:3}"
              :xl="{span:16, offset:4}"
          >
            <global-nav></global-nav>
          </el-col>
        </el-row>
      </el-header>
      <el-main style="min-height: 100%" class="main">
        <el-row>
          <el-col
              :xs="{span:24, offset:0}"
              :sm="{span:22, offset:1}"
              :md="{span:20, offset:2}"
              :lg="{span:18, offset:3}"
              :xl="{span:16, offset:4}"
              class="content"
          >
            <router-view></router-view>
          </el-col>
        </el-row>
      </el-main>
    </el-container>
    <el-dialog title="你怎么找到这个的？" :visible.sync="show">
      <div>
        <p>Naive UI真好看...哪天重写吧555</p>
        <h4>网站计划</h4>
        <h5>一期</h5>
        <ul>
          <li>浏览页 √</li>
          <li>目录页 √</li>
          <li>社团浏览页 √</li>
          <li>游戏统计页 √</li>
          <li>游戏预告页 √</li>
          <li>定时更新游戏数据 √</li>
          <li>定时收集预告 √</li>
        </ul>
        <el-divider></el-divider>
        <h5>二期</h5>
        <ul>
          <li>用户系统</li>
          <li>游戏评论、打分</li>
          <li>预告游戏关注，发售提醒、预告提醒</li>
          <li>用户注册配套的QQ机器人</li>
        </ul>
      </div>
    </el-dialog>
    <!-- 右下角将背景图加入收藏按钮 -->
    <el-button type="danger" circle class="save-bg" @click="saveBg" v-show="login"><font-awesome-icon icon="fa-regular fa-heart" /></el-button>
  </div>
</template>

<script>

import axios from "axios";
import {EventBus} from "@/EventBus";

export default {
  name: 'App',
  data() {
    return {
      keys: ['ArrowUp', 'ArrowUp', 'ArrowDown', 'ArrowDown', 'ArrowLeft', 'ArrowRight', 'ArrowLeft', 'ArrowRight', 'b', 'a'],
      count: 0,
      timeout: null,
      show: false,
      background: {
        'id': '',
        'url': ''
      },
      login: false
    }
  },
  methods: {
    getBackGround() {
      let documentElement = document.documentElement;
      let aspect = documentElement.clientWidth / documentElement.clientHeight;
      let token = localStorage.getItem('token');
      let config = {}
      if (token !== null) {
        config = {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }
      }

      axios.get('/api/open/background?aspect=' + (aspect > 1 ? 'pc' : 'mp'), config).then(res => {
        if (res.data.code === 200) {
          this.background = res.data.data
          document.querySelector('body').style.backgroundImage = `url(${this.background.url})`
        }
      })
    },
    checkScrollAtTop() {
      if (window.scrollY > 0) {
        document.querySelector('.el-menu').setAttribute('class', 'el-menu at-top')
      } else {
        document.querySelector('.el-menu').setAttribute('class', 'el-menu')
      }
    },
    easterEgg(event) {
      if (event.key === this.keys[this.count]) {
        this.count++

        if (this.count === this.keys.length) {
          this.count = 0
          this.show = true
          clearTimeout(this.timeout)
        }
        if (this.timeout != null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.count = 0
        }, 1000)
      } else {
        this.count = 0
      }
    },
    saveBg() {
      let token = 'Bearer ' + localStorage.getItem('token')

      axios.post('/api/user/background', this.background, {
        headers: {
          'Authorization': token
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '收藏成功',
            message: res.data.message
          })
          EventBus.$emit('changeBg')
        } else if (res.data.code === 400) {
          this.$notify.warning({
            title: '收藏失败',
            message: res.data.message
          })
        } else if (res.data.code === 500) {
          this.$notify.error({
            title: '收藏失败',
            message: res.data.message
          })
        }
      })
    }
  },
  beforeMount() {
    this.login = localStorage.getItem('token') !== null
  },
  mounted() {
    EventBus.$on('changeState', () => {
      this.login = localStorage.getItem('token') !== null
    })

    this.getBackGround()
    window.addEventListener("scroll", this.checkScrollAtTop)
    window.addEventListener('keyup', ev => this.easterEgg(ev))
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.checkScrollAtTop)
    window.removeEventListener('keyup', ev => this.easterEgg(ev))
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

body {
  background-size: cover;
  background-attachment: fixed;
}

.el-container {
  position: relative;
  width: 100%;
}

.el-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 61px;
  z-index: 1;
}

.main{
  margin-top: 61px;
}

.at-top {
  background-color: rgb(240, 240, 240) !important;
}

.content {
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.save-bg {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1;
}
</style>
