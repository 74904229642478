<script>
export default {
  name: 'NotFound',
  mounted() {
    // 获取浏览器窗口高度
    const winHeight = window.innerHeight;
    this.$refs.father.style.height = winHeight - 61 - 20 - 20 - 28 + 'px';
  }
}
</script>

<template>
  <div class="not-found" ref="father">
    <img src="/pic_not_found_01.png" alt="404"/>
    <h1>这是什么地方？</h1>
  </div>
</template>

<style scoped>
.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>