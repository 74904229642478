<script>
import axios from "axios";
import {EventBus} from "@/EventBus";
export default {
  name: 'BackgroundCard',
  data() {
    return {
      bg: {
        id: '',
        imageUrl: '',
        enable: true
      }
    }
  },
  props: ['background'],
  mounted() {
    this.bg = this.background
  },
  methods: {
    changeBackgroundStatus() {
      axios.put('/api/user/background', {
        id: this.bg.id,
        enable: this.bg.enable
      }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '操作成功',
            message: '背景设置已更新'
          })
        } else if (res.data.code === 400) {
          this.$notify.warning({
            title: '操作失败',
            message: res.data.message
          })
        } else {
          this.$notify.error({
            title: '操作失败',
            message: res.data.message
          })
        }

        if (res.data.code === 400 || res.data.code === 500) {
          setTimeout(() => {
            this.bg.enable = false
          }, 1000)
        }
      })
    },
    deleteBackground() {
      this.$confirm('此操作将永久删除该背景, 是否继续?（真的很久）', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        axios.delete('/api/user/background', {
          data: {
            id: this.bg.id
          },
          headers: {
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }
        }).then(res => {
          if (res.data.code === 200) {
            this.$notify.success({
              title: '操作成功',
              message: '背景已删除'
            })
            EventBus.$emit('deleteBackground')
          } else {
            this.$notify.error({
              title: '操作失败',
              message: res.data.message
            })
          }
        })
      }).catch(() => {
        this.$notify.info({
          title: '已取消',
          message: '操作已取消'
        })
      })
    }
  }
}
</script>

<template>
  <div class="card">
    <el-card>
      <el-image :src="bg.imageUrl"></el-image>
      <div style="padding: 5px; display: flex; justify-content: space-between; align-items: baseline">
        <div>
          <el-switch v-model="bg.enable" active-color="#13ce66" inactive-color="#ff4949" active-text="启用" inactive-text="禁用"
                     @change="changeBackgroundStatus"></el-switch>
        </div>
        <el-button type="text" @click="deleteBackground"><font-awesome-icon icon="fa-solid fa-trash-can" /></el-button>
      </div>
    </el-card>
  </div>
</template>

<style scoped>
.card {
  margin: 10px;
}

.el-button {
  color: #606266
}

.el-button :hover {
  color: #F56C6C
}
</style>
