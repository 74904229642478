<script>
import axios from "axios";
import GamePreviewCard from "@/components/GamePreviewCard.vue";
import GamePreviewCardXs from "@/components/GamePreviewCardXs.vue";
import {EventBus} from "@/EventBus";

export default {
  name: 'GamePreview',
  components: {GamePreviewCardXs, GamePreviewCard},
  data() {
    return {
      year: new Date().getFullYear(),
      month: new Date().getMonth() + 1,
      first: [],
      middle: [],
      last: [],
      loading: true,
      actives: [],
      collapseYear: this.year,
      collapseMonth: this.month,
      widthSmall: false,
      selectDate: '',
      activeName: '1',
      pickerOptions: {
        disabledDate(time) {
          let date = new Date(time.getTime());
          let today = new Date();
          let year = today.getFullYear();
          let month = today.getMonth() + 1;

          if (date.getFullYear() === year) {
            return date.getMonth() + 1 < month
          } else {
            return date.getMonth() + 1 >= month
          }
        }
      }
    }
  },
  methods: {
    getPreviewData(year, month) {
      let date = new Date();
      let nowYear = date.getFullYear();
      let nowMonth = date.getMonth();

      this.collapseYear = year
      this.collapseMonth = month

      if (nowYear === Number(year) && nowMonth + 1 === Number(month)) {
        let day = date.getDate();
        if (day <= 10) {
          this.actives = ['1']
        } else if (day <= 20) {
          this.actives = ['2']
        } else {
          this.actives = ['3']
        }
      } else {
        this.actives = ['1']
      }

      this.first = []
      this.middle = []
      this.last = []

      let token = localStorage.getItem('token');
      axios.post('/api/open/preview', {
        'year': year,
        'month': month
      }, {
        headers: {
          'Authorization': token === null ? '' : 'Bearer ' + token
        }
      }).then(res => {
        let previewInfo = res.data.data;
        this.first = previewInfo.first
        this.middle = previewInfo.middle
        this.last = previewInfo.last
      })
    },
    rollToTop() {
      window.scrollTo(0, 0)
    },
    resize() {
      let width = document.documentElement.clientWidth
      this.widthSmall = width < 768;
    },
    selectMonth() {
      this.collapseMonth = this.selectDate.getMonth() + 1
      this.collapseYear = this.selectDate.getFullYear()

      this.getPreviewData(this.collapseYear, this.collapseMonth)
    }
  },
  mounted() {
    this.getPreviewData(this.year, this.month)
    this.resize()
    addEventListener('resize', this.resize)

    EventBus.$on('refreshPreviewList', () => {
      this.first = []
      this.middle = []
      this.last = []
      this.getPreviewData(this.collapseYear, this.collapseMonth)
    })
  },
  beforeDestroy() {
    removeEventListener('resize', this.resize)
  },
  computed: {
    firstName() {
      return `${this.collapseYear}年${this.collapseMonth}月上旬`
    },
    middleName() {
      return `${this.collapseYear}年${this.collapseMonth}月中旬`
    },
    lastName() {
      return `${this.collapseYear}年${this.collapseMonth}月下旬`
    },
    buttons() {
      let buttons = []
      let month = 1
      let index = 1

      for (let i = this.month; i < 13; i++) {
        buttons.push({id: index, year: this.year, month: i})
        index += 1
      }

      while (buttons.length < 12) {
        buttons.push({id: index, year: Number(this.year) + 1, month: month})
        month += 1
        index += 1
      }

      return buttons
    },
    datePickerPlaceholder() {
      return `请选择${this.year}年${this.month}月至${this.year + 1}年${this.month - 1}月的预告`
    }
  }
}
</script>

<template>
  <div style="margin-bottom: 20px" class="preview-root">
    <el-date-picker
        v-model="selectDate"
        type="month"
        :placeholder="datePickerPlaceholder"
        style="width: 100%; margin-bottom: 10px"
        format="yyyy-MM"
        @change="selectMonth($event)"
        v-if="widthSmall"
        :picker-options="pickerOptions"
    />
    <div v-else>
      <div style="width: 100%" class="month-select" ref="month">
        <div style="display: flex; justify-content: center; margin-bottom: 10px">
          <el-button-group v-for="btn in buttons" :key="btn.id" class="button-group">
            <el-button type="primary"
                       @click="getPreviewData(btn.year, btn.month)"
                       size="medium"
                       plain
            >
              {{ btn.year }}年{{ btn.month }}月
            </el-button>
          </el-button-group>
        </div>
      </div>
    </div>

    <el-collapse v-model="actives" accordion @change="rollToTop">
      <el-collapse-item name="1" :title="firstName">
        <div class="block">
          <div v-if="widthSmall">
            <el-row v-for="game in first" :key="game.gameId">
              <el-col :span="24">
                <game-preview-card-xs :game="game" :time="firstName"></game-preview-card-xs>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-timeline>
              <el-timeline-item v-for="game in first" :key="game.gameId" :timestamp="game.saleDate" placement="top"
                                style="padding-right: 20px">
                <game-preview-card :game="game"></game-preview-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item :title="middleName" name="2">
        <div class="block">
          <div v-if="widthSmall">
            <el-row v-for="game in middle" :key="game.gameId">
              <el-col :span="24">
                <game-preview-card-xs :game="game" :time="middleName"></game-preview-card-xs>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-timeline>
              <el-timeline-item v-for="game in middle" :key="game.gameId" :timestamp="game.saleDate" placement="top"
                                style="padding-right: 20px">
                <game-preview-card :game="game"></game-preview-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </el-collapse-item>
      <el-collapse-item :title="lastName" name="3">
        <div class="block">
          <div v-if="widthSmall">
            <el-row v-for="game in last" :key="game.gameId">
              <el-col :span="24">
                <game-preview-card-xs :game="game" :time="lastName"></game-preview-card-xs>
              </el-col>
            </el-row>
          </div>
          <div v-else>
            <el-timeline>
              <el-timeline-item v-for="game in last" :key="game.gameId" :timestamp="game.saleDate" placement="top"
                                style="padding-right: 20px">
                <game-preview-card :game="game"></game-preview-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<style scoped>
.button-group:first-child .el-button {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.button-group:last-child .el-button {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
</style>
