<script>
export default {
  name: 'GamePreviewCardXs',
  props: ['game', 'time'],
  data() {
    return {
      info: {
        imageUrl: '',
        gameType: '',
        gameUrl: '',
        name: '',
        gameId: '',
        makerUrl: '',
        makerName: '',
        genre: [],
        price: '',
        tags: [],
        date: ''
      }
    }
  },
  mounted() {
    this.info = this.game
    this.info.imageUrl = 'https:' + this.info.imageUrl
    if (this.game.saleDate === '未定') {
      this.date = this.time
    } else {
      this.date = this.game.saleDate
    }
  }
}
</script>

<template>
  <div>
    <el-card class="card">
      <el-image :src="info.imageUrl" size="small"></el-image>
      <div>
        <h3 class="game-id">{{ info.gameId }}
          <el-tag>{{ info.gameType }}</el-tag>
        </h3>
        <h3 class="game-name"><a :href="info.gameUrl">{{ info.name }}</a></h3>
        <h4 class="maker"><a :href="info.makerUrl">{{ info.makerName }}</a></h4>
        <div v-for="genre in info.genre" :key="genre" style="display: inline-block" class="genre">
          <el-tag size="mini" style="margin-right: 5px" type="danger" v-if="genre === '全年齢'">{{ genre }}</el-tag>
          <el-tag size="mini" style="margin-right: 5px" type="warning" v-else>{{ genre }}</el-tag>
        </div>
        <div style="font-weight: bold; font-size: 16px" class="price">
          <font-awesome-icon icon="fa-regular fa-calendar"/>
          {{ this.date }}
          <el-divider direction="vertical"></el-divider>
          <font-awesome-icon icon="fa-regular fa-credit-card"/>
          <span style="color: #F56C6C; "> {{ info.price }}</span>
        </div>
        <div v-for="tag in info.tags" :key="tag" style="display: inline-block" class="tag">
          <el-tag size="small" style="margin-right: 5px">{{ tag }}</el-tag>
        </div>
      </div>
    </el-card>
  </div>
</template>

<style scoped>
.card {
  margin-bottom: 10px;
  border-radius: 10px;
}

.game-name {
  margin: 0;
}

.game-id {
  margin-top: 10px;
  margin-bottom: 0;
}

.maker {
  margin-top: 10px;
  margin-bottom: 20px;
}

.genre {
  margin-top: 10px;
}

.price {
  margin-top: 5px;
}

.tag {
  margin-top: 5px;
}

a {
  color: #409EFF;
  text-decoration: none;
}
</style>
