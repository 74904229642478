<script>
import {EventBus} from "@/EventBus";
import axios from "axios";

export default {
  name: 'GlobalNav',
  mounted() {
    // this.selectFirst()
  },
  data() {
    return {
      login: false,
      user: {
        'username': '',
        'avatar': '',
        'admin': false
      }
    }
  },
  methods: {
    selectFirst() {
      this.$router.push('/')
    },
    getRole() {
      let token = localStorage.getItem('token');
      if (token === null) {
        this.login = false
        this.user.username = ''
        this.user.avatar = ''
        this.user.admin = false
        return
      }
      axios.post('/api/getRole', {}, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then(res => {
        let data = res.data.data;

        if (data.id === null) {
          this.login = false
          this.user.username = ''
          this.user.avatar = ''
          this.user.admin = false
          localStorage.removeItem('token')
          EventBus.$emit('changeState')
          return
        }

        this.user.username = data.username
        this.user.admin = data.role.includes('管理员')

        this.login = true
      }).catch(() => {
        this.login = false
        this.user.username = ''
        this.user.avatar = ''
        this.user.admin = false
        localStorage.removeItem('token')
        EventBus.$emit('changeState')
      })

      axios.get('/api/user/avatar', {
        headers: {'Authorization': 'Bearer ' + token},
        responseType: 'blob'
      }).then(res => {
        // 图片流
        let fileType = res.headers['content-disposition'].split('.')[1]
        let blob
        if (fileType === 'jpeg' || fileType === 'jpg') {
          blob = new Blob([res.data], {type: 'image/jpeg'})
        } else {
          blob = new Blob([res.data], {type: 'image/png'})
        }
        this.user.avatar = URL.createObjectURL(blob)
      }).catch(() => {
        this.user.avatar = ''
      })
    },
    logout() {
      axios.post('/api/user/logout', {}, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          localStorage.removeItem('token')
          this.login = false
          this.user = {}
          this.$notify.success({
            title: '退出成功',
            message: '欢迎下次再来'
          })
        }
        EventBus.$emit('changeState')
        if (this.$route.path === '/settings') {
          this.$router.push('/')
        }
      })
    },
    doLogin() {
      if (this.$route.path === '/login') {
        return
      }
      localStorage.setItem('beforeLogin', this.$route.path)
      this.$router.push('/login')
    },
    settings() {
      let token = localStorage.getItem('token')
      axios.post('/api/getRole', {}, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((res) => {
        if (res.data.data.id === null) {
          this.$notify.error({
            title: '登录过期',
            message: '请重新登录'
          })
          localStorage.removeItem('token')
          EventBus.$emit('changeState')
          this.$router.push('/login')
        } else {
          this.$router.push('/settings')
        }
      }).catch(() => {
        this.$notify.error({
          title: '登录过期',
          message: '请重新登录'
        })
        localStorage.removeItem('token')
        EventBus.$emit('changeState')
        this.$router.push('/login')
      })
    },
    admin() {
      let token = localStorage.getItem('token')
      axios.post('/api/getRole', {}, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      }).then((res) => {
        if (res.data.data.id === null) {
          this.$notify.error({
            title: '登录过期',
            message: '请重新登录'
          })
          localStorage.removeItem('token')
          EventBus.$emit('changeState')
          this.$router.push('/login')
        } else {
          this.$router.push('/admin')
        }
      }).catch(() => {
        this.$notify.error({
          title: '登录过期',
          message: '请重新登录'
        })
        localStorage.removeItem('token')
        EventBus.$emit('changeState')
        this.$router.push('/login')
      })
    }
  },
  created() {
    if (localStorage.getItem('token') !== null) {
      this.getRole()
    }

    EventBus.$on('changeState', () => {
      this.getRole()
    })
  }
}
</script>

<template>
  <div>
    <el-menu router mode="horizontal" default-active="$router.path">
      <el-menu-item index="/">浏览</el-menu-item>
      <el-menu-item index="/table">目录</el-menu-item>
<!--      <el-menu-item index="/genre">类型</el-menu-item>-->
      <el-menu-item index="/maker">社团</el-menu-item>
      <el-menu-item index="/statistics">统计</el-menu-item>
      <el-menu-item index="/announce">预告</el-menu-item>
      <el-submenu v-if="login" index="submenu">
        <template slot="title">
          <el-avatar :src="user.avatar"></el-avatar>
        </template>
        <el-menu-item disabled>{{ user.username }}</el-menu-item>
        <el-menu-item @click="settings">个人中心</el-menu-item>
        <el-menu-item v-if="user.admin" @click="admin">网站管理</el-menu-item>
        <el-menu-item @click="logout">退出</el-menu-item>
      </el-submenu>
      <el-menu-item v-else @click="doLogin">登录</el-menu-item>
    </el-menu>
  </div>
</template>

<style scoped>
.el-menu {
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
  border-radius: 10px;
}

.el-menu-item {
  color: #606266;
}

.is-active {
  color: #303133;
}
</style>
