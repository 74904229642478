<script>
import axios from "axios";
import MakerGameItem from "@/components/MakerGameItem.vue";

export default {
  name: 'GameMaker',
  components: {MakerGameItem},
  data() {
    return {
      makers: [],
      menuMakers: [],
      value: '',
      part: 1,
      chunkSize: 100,
      key: '',
      timeout: null,
      showSmallMenu: true,
      firstLoadComplete: false,
      loadComplete: true,
      makerInfo: null,
      mainHeight: 0,
      widthLevel: 0,
      collapseMenu: false,
      openCollapse: []
    }
  },
  mounted() {
    axios.get('/api/open/select/maker').then(res => {
      this.makers = res.data.data.map(maker => {
        maker.label = maker.label.split('(')[0]
        return maker
      })
      this.menuMakers = this.makers.slice(0, this.chunkSize)
    })

    window.addEventListener('resize', this.resize)
    this.resize()
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  },
  methods: {
    loadMore() {
      this.menuMakers = this.menuMakers.concat(this.makers.slice(this.part * this.chunkSize, this.part * this.chunkSize + this.chunkSize))
      this.part++
    },
    search(key) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(() => {
        if (key === '') {
          this.clearSearch()
          return
        }
        let newMakers = this.makers.filter(maker => maker.label.toLowerCase().includes(key))
        this.showSmallMenu = false
        setTimeout(() => {
          this.menuMakers = newMakers
          this.showSmallMenu = true
        }, 500)
      }, 500)
    },
    clearSearch() {
      this.loadComplete = false
      this.menuMakers = this.makers.slice(0, this.chunkSize)
      this.part = 1
      this.showSmallMenu = true
    },
    checkDisabled() {
      let disable = this.menuMakers.length === this.makers.length
      this.firstLoadComplete = this.firstLoadComplete || disable
      if (this.firstLoadComplete) {
        this.loadComplete = this.loadComplete || disable
      }
      return disable
    },
    selectMenu(index) {
      axios.get('/api/open/maker/' + index).then(res => {
        this.makerInfo = res.data.data
        // main的高度 - div的padding - 分割线margin - 分割线高度 - h1的margin
        let tempHeight = this.mainHeight - 20 - 20 - 24 - 24 - 1 - 30 - 25
        setTimeout(() => {
          let makerInfoHeight = document.querySelector('.maker-info').clientHeight
          document.querySelector('.game-list-wrapper').style.height = tempHeight - makerInfoHeight + 'px'
        }, 1)

        if (this.widthLevel === 1) {
          this.collapseMenu = true
          this.openCollapse = []
        }
      })
    },
    resize() {
      let width = document.documentElement.clientWidth
      if (width < 768) {
        this.widthLevel = 1
      } else if (width < 992) {
        this.widthLevel = 2
      } else if (width < 1200) {
        this.widthLevel = 3
      } else if (width < 1920) {
        this.widthLevel = 4
      } else {
        this.widthLevel = 5
      }

      if (this.widthLevel === 1 && this.makerInfo !== null) {
        this.collapseMenu = true
      } else {
        this.collapseMenu = false
      }

      // 页面高度
      let clientHeight = document.documentElement.clientHeight
      // 60是header的高度，20是main的margin 40是input高度 5是aside的margin-top
      this.mainHeight = clientHeight - 60 - 20 - 20
      let asideHeight = this.mainHeight - 10 - 40 - 25
      document.querySelector('.infinite-list-wrapper').style.height = asideHeight + 'px'

      let tempHeight = this.mainHeight - 20 - 20 - 24 - 24 - 1 - 30 - 25
      if (this.makerInfo !== null) {
        let makerInfoHeight = document.querySelector('.maker-info').clientHeight
        document.querySelector('.game-list-wrapper').style.height = tempHeight - makerInfoHeight + 'px'
      }

      if (!this.firstLoadComplete) {
        setTimeout(() => {
          let mask = document.querySelector('.el-loading-mask');
          if (mask) {
            mask.style.height = this.mainHeight - 38 + 'px'
            mask.style.borderRadius = '10px'
          }
        }, 20)
      }
    }
  },
  computed: {
    disabled() {
      return this.checkDisabled()
    },
    percent() {
      if (this.makers.length === 0) {
        return "加载数据中 0%"
      }
      let percent = this.menuMakers.length / this.makers.length * 100
      return "加载数据中 " + percent.toFixed(2) + "%"
    }
  }
}
</script>

<template>
  <div>
    <el-row v-loading="!firstLoadComplete" :element-loading-text="percent">
      <el-col
          v-loading="!loadComplete"
          :element-loading-text="percent"
          :xs="24" :sm="8" :md="6" :lg="5" :xl="4"
      >
        <div v-if="!collapseMenu">
          <el-input
              v-model="key"
              clearable
              placeholder="筛选社团"
              @input="search($event)"
              @clear="clearSearch"
          ></el-input>
          <div class="infinite-list-wrapper" style="overflow-x: hidden">
            <el-menu
                v-show="showSmallMenu"
                v-for="maker in menuMakers"
                :key="maker.value"
                v-infinite-scroll="loadMore"
                :infinite-scroll-disabled="disabled"
                @select="selectMenu($event)">
              <el-menu-item :index="maker.value">
                {{ maker.label }}
              </el-menu-item>
            </el-menu>
          </div>
        </div>
        <div v-else>
          <el-collapse v-model="openCollapse">
            <el-collapse-item name="selectMakers">
              <template slot="title">
                <span style="margin-left: 20px;font-size: 16px">选择社团</span>
              </template>
              <el-input
                  v-model="key"
                  clearable
                  placeholder="筛选社团"
                  @input="search($event)"
                  @clear="clearSearch"
              ></el-input>
              <div class="infinite-list-wrapper" style="overflow-x: hidden">
                <el-menu
                    v-show="showSmallMenu"
                    v-for="maker in menuMakers"
                    :key="maker.value"
                    v-infinite-scroll="loadMore"
                    :infinite-scroll-disabled="disabled"
                    @select="selectMenu($event)">
                  <el-menu-item :index="maker.value">
                    {{ maker.label }}
                  </el-menu-item>
                </el-menu>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </el-col>
      <el-col
          :xs="24" :sm="16" :md="18" :lg="19" :xl="20"
      >
        <div v-if="makerInfo !== null" class="game-info">
          <div class="maker-info">
            <div class="maker-name-wrapper">
              <el-avatar :src="makerInfo.avatar" v-if="makerInfo.avatar"></el-avatar>
              <el-avatar icon="el-icon-user-solid" v-else></el-avatar>
              <h1 class="maker-name"> {{ makerInfo.name }} ({{ makerInfo.dlsiteId }})</h1>
            </div>
            <span v-if="makerInfo.dlsiteUrl" class="site-span">
              <img src="/dlsite.ico" alt="dlsite" class="site-icon"/>&nbsp;
              <a :href="makerInfo.dlsiteUrl" target="_blank">dlsite</a>
            </span>
            <span v-if="makerInfo.ciEnUrl" class="site-span">
              <el-divider direction="vertical"></el-divider>
              <img src="/cien.ico" alt="ci-en" class="site-icon"/>&nbsp;
              <a :href="makerInfo.ciEnUrl" target="_blank">ci-en</a>
            </span>
            <span v-if="makerInfo.dmmUrl" class="site-span">
              <el-divider direction="vertical"></el-divider>
              <img src="/dmm.ico" alt="dmm" class="site-icon"/>
              <a :href="makerInfo.dmmUrl" target="_blank">fanza(dmm)</a>
            </span>
            <span v-if="makerInfo.pixivUrl" class="site-span">
              <el-divider direction="vertical"></el-divider>
              <img src="/pixiv.ico" alt="pixiv" class="site-icon"/>&nbsp;
              <a :href="makerInfo.pixivUrl" target="_blank">pixiv</a>
            </span>
            <span v-if="makerInfo.twitterUrl" class="site-span">
              <el-divider direction="vertical"></el-divider>
              <img src="/twitter.ico" alt="twitter" class="site-icon"/>&nbsp;
              <a :href="makerInfo.twitterUrl" target="_blank">x(twitter)</a>
            </span>
            <span v-if="makerInfo.homepageUrl" class="site-span">
              <el-divider direction="vertical"></el-divider>
              <font-awesome-icon icon="fa-solid fa-house"/>&nbsp;
              <a :href="makerInfo.homepageUrl" target="_blank">homepage</a>
            </span>
            <span v-if="makerInfo.blogUrl" class="site-span">
              <el-divider direction="vertical"></el-divider>
              <font-awesome-icon icon="fa-solid fa-blog"/>&nbsp;
              <a :href="makerInfo.blogUrl" target="_blank">blog</a>
            </span>
          </div>
          <el-divider></el-divider>
          <div class="game-list-wrapper">
            <div v-for="game in makerInfo.gameList" :key="game.id">
              <maker-game-item :game="game"></maker-game-item>
              <el-divider></el-divider>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<style scoped>
.infinite-list-wrapper {
  overflow-y: scroll;
  margin-top: 5px;
  background-color: rgba(255, 255, 255, 0);
}

ul {
  background-color: transparent;
}

li {
  background-color: transparent;
}

.game-list-wrapper {
  overflow-y: scroll;
}

.el-menu {
  width: 99%;
}

.game-info {
  margin-left: 20px;
}

.maker-name {
  font-size: 30px;
}

.site-icon {
  width: 13px;
  height: 13px;
}

.el-input {
  width: 99%;
  background-color: rgba(255, 255, 255, 0.6);
}

input {
  border-radius: 10px;
}

h1 {
  display: inline-block;
  padding-left: 20px;
}

.maker-name-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
</style>