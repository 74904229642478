<script>
import GameCard from '@/components/Card.vue';
import axios from "axios";
import {EventBus} from "@/EventBus";

export default {
  name: 'GameHome',
  components: {GameCard},
  data() {
    return {
      gameData: [],
      loading: false,
      totalPage: 0,
      currentPage: 0,
      heights: [],
      paginationPager: 7,
      chunkSize: 0,
      allGameData: [],
      timeout: null,
      sampleList: [],
      showDialog: false,
      dialogType: '',
      dialogHeight: '',
      dialogWidth: ''
    }
  },
  methods: {
    load(pageNum, start) {
      if (!this.loading) {
        this.loading = true
        axios.get('/api/open/home', {
          params: {
            pageNo: pageNum
          }
        }).then(res => {
          this.allGameData = res.data.data.rows

          this.setRows()

          this.currentPage = res.data.data.current
          if (start) {
            this.totalPage = res.data.data.pageCount
          }
          this.loading = false
        })
      }
    },
    setRows() {
      let width = document.documentElement.clientWidth
      if (width < 768) {
        this.paginationPager = 5
        this.chunkSize = 1
        this.dialogType = ''
        this.dialogWidth = '100%'
      } else if (width < 992) {
        this.paginationPager = 7
        this.chunkSize = 2
        this.dialogType = ''
        this.dialogWidth = '90%'
      } else if (width < 1200) {
        this.paginationPager = 9
        this.chunkSize = 3
        this.dialogType = '80%'
      } else if (width < 1920) {
        this.paginationPager = 11
        this.chunkSize = 4
        this.dialogType = '75%'
      } else {
        this.paginationPager = 15
        this.chunkSize = 6
        this.dialogType = 'card'
        this.dialogWidth = '50%'
      }

      this.gameData = []

      for (let i = 0; i < this.allGameData.length; i += this.chunkSize) {
        this.gameData.push(this.allGameData.slice(i, i + this.chunkSize))
      }
    },
    resize() {
      if (this.timeout != null) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => {
        this.setRows()
      }, 100)
    },
    setCarouselHeight() {
      let eleList = document.getElementsByClassName('is-active');
      for (let i = 0; i < eleList.length; i++) {
        let ele = eleList[i];
        if (ele.tagName === 'DIV') {
          let imgHeight = ele.lastChild.clientHeight + 'px';
          if (this.dialogHeight < imgHeight) {
            this.dialogHeight = imgHeight
          }
        }
      }
    },
  },
  created() {
    if (!this.loading) {
      this.loading = true
      axios.get('/api/open/home', {
        params: {
          pageNo: 1
        }
      }).then(res => {
        this.allGameData = res.data.data.rows
        this.currentPage = res.data.data.current
        this.totalPage = res.data.data.pageCount

        this.setRows()

        this.loading = false
      })
    }
  },
  mounted() {
    window.addEventListener('resize', this.resize)
    EventBus.$on('showImage', samples => {
      this.sampleList = samples
      this.showDialog = true
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resize)
  }
}
</script>

<template>
  <div>
    <el-pagination
        background
        layout="prev, pager, next"
        :page-count="totalPage"
        :current-page="currentPage"
        :pager-count="paginationPager"
        @current-change="load($event, false)"
    ></el-pagination>
    <el-row :gutter="20" v-for="(chunk, index) in gameData" :key="index">
      <el-col
          v-for="game in chunk"
          :key="game.id"
          :span="24 / chunkSize"
      >
        <game-card :game="game"></game-card>
      </el-col>
    </el-row>
    <el-pagination
        background
        layout="prev, pager, next"
        :page-count="totalPage"
        :current-page="currentPage"
        :pager-count="paginationPager"
        @current-change="load($event, false)"
        style="padding-bottom: 20px"
    ></el-pagination>

    <el-dialog :visible.sync="showDialog" :width="dialogWidth">
      <el-carousel :type="dialogType" :height="dialogHeight" @change="setCarouselHeight">
        <el-carousel-item v-for="(sample, index) in sampleList" :key="index">
          <img alt="" :src="sample" style="width: 100%">
        </el-carousel-item>
      </el-carousel>
    </el-dialog>
  </div>
</template>

<style scoped>
.el-row {
  margin-top: 20px
}

.el-pagination {
  margin-top: 20px;
  text-align: center;
}
</style>
