<script>
import axios from 'axios'
import {EventBus} from "@/EventBus";

export default {
  name: 'UserInfo',
  data() {
    return {
      userInfo: {
        username: '',
        qqNumber: ''
      },
      passwords: {
        oldPassword: '',
        newPassword: ''
      },
      rules: {
        username: [
          {required: true, message: '请输入用户名', trigger: 'blur'}
        ],
        qqNumber: [
          {required: true, message: '请输入qq号', trigger: 'blur'},
          {pattern: /^[1-9][0-9]{4,14}$/, message: '请输入正确的qq号', trigger: 'blur'}
        ],
      },
      imageUrl: ''
    }
  },
  computed: {
    header() {
      return {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    }
  },
  mounted() {
    this.getUserInfo()
    this.getAvatar()
    let element = document.querySelector('.el-upload');
    element.style.border = '1px dashed #909399'
    element.style.borderRadius = '6px'

    element.onmouseover = function() {
      element.style.borderColor = '#409EFF'
    }

    element.onmouseout = function() {
      element.style.borderColor = '#909399'
    }
  },
  methods: {
    getUserInfo() {
      axios.get('/api/user/info', {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 400) {
          this.$notify.warning({
            title: '',
            message: 'res.data.message'
          })
        } else if (res.data.code === 500) {
          this.$notify.error({
            title: '',
            message: 'res.data.message'
          })
        } else if (res.data.code === 200) {
          this.userInfo = res.data.data
        }
      })
    },
    getAvatar(){
      axios.get('/api/user/avatar', {
        headers: {'Authorization': 'Bearer ' + localStorage.getItem('token')},
        responseType: 'blob'
      }).then(res => {
        // 图片流
        let fileType = res.headers['content-disposition'].split('.')[1]
        let blob
        if (fileType === 'jpeg' || fileType === 'jpg') {
          blob = new Blob([res.data], {type: 'image/jpeg'})
        } else {
          blob = new Blob([res.data], {type: 'image/png'})
        }
        this.imageUrl = URL.createObjectURL(blob)
      })
    },
    updateUserInfo() {
      axios.post('/api//user/info', this.userInfo, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '修改成功',
            message: '个人信息已更新'
          })
          this.getUserInfo()
        }
      })
    },
    updateUserPassword() {
      if (this.passwords.oldPassword === '' || this.passwords.newPassword === '') {
        this.$notify.error({
          title: '输入错误',
          message: '请输入旧密码和新密码'
        })
        return
      }

      axios.post('/api/user/password', this.passwords, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '密码修改成功',
            message: '请重新登录'
          })
          this.passwords.oldPassword = ''
          this.passwords.newPassword = ''
          localStorage.removeItem('token')
          EventBus.$emit('changeState')
          this.$router.push('/login')
        } else if (res.data.code === 500) {
          this.$notify.error({
            title: '修改失败',
            message: res.data.message
          })
          this.passwords.oldPassword = ''
          this.passwords.newPassword = ''
        }
      })
    },
    onAvatarChange(file) {
      const isJPG = file.raw.type === 'image/jpeg' || file.raw.type === 'image/png';
      const isLt2M = file.raw.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 或 PNG 格式!');
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!');
      }
      this.imageUrl = URL.createObjectURL(file.raw)
      return isJPG && isLt2M;
    },
    onSuccess(response) {
      console.log(response)
      if (response.code === 200) {
        this.$notify.success({
          title: '上传成功',
          message: '头像已更新'
        })
        EventBus.$emit('changeState')
      } else {
        this.$notify.error({
          title: '上传失败',
          message: response.data.message
        })
      }
    },
    uploadAvatar() {
      this.$refs.upload.submit()
    }
  }
}
</script>

<template>
  <div>
    <div class="basic-info-form">
      <h4>个人信息</h4>
      <el-form :model="userInfo" :rules="rules" label-width="75px">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="userInfo.username" placeholder="请输入用户名" disabled></el-input>
        </el-form-item>
        <el-form-item label="QQ" prop="qqNumber">
          <el-input
              v-model="userInfo.qqNumber"
              placeholder="请输入QQ号"
              @input="value => num = value.replace(/[^d]/g,'')"
          ></el-input>
        </el-form-item>
        <div style="display: flex; justify-content: flex-end">
          <el-button type="primary" @click="updateUserInfo">保存</el-button>
        </div>
      </el-form>
    </div>
    <el-divider></el-divider>
    <div class="password-form">
      <h4>修改密码</h4>
      <el-form :model="passwords" label-width="75px">
        <el-form-item label="旧密码">
          <el-input v-model="passwords.oldPassword" type="password" placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="passwords.newPassword" type="password" placeholder="请输入新密码"></el-input>
        </el-form-item>
        <div style="display: flex; justify-content: flex-end">
          <el-button type="primary" @click="updateUserPassword">保存</el-button>
        </div>
      </el-form>
    </div>
    <el-divider></el-divider>
    <h4>修改头像</h4>
    <el-upload
        ref="upload"
        class="avatar-uploader"
        action="/api/user/avatar"
        accept="image/png, image/jpeg"
        :headers="header"
        :auto-upload="false"
        :show-file-list="false"
        :on-change="onAvatarChange"
        :on-success="onSuccess"
        :limit="1"
    >
      <div v-if="imageUrl">
        <img :src="imageUrl" class="avatar" alt="">
      </div>
      <i v-else class="el-icon-plus avatar-uploader-icon"></i>
    </el-upload>
    <div style="display: flex; justify-content: flex-end">
      <el-button type="primary" @click="uploadAvatar">保存</el-button>
    </div>
  </div>
</template>

<style scoped>
.avatar-uploader .el-upload {
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>