<script>
import axios from "axios";
import {EventBus} from "@/EventBus";
import BackgroundCard from "@/components/BackgroundCard.vue";


export default {
  name: 'UserBackground',
  props: ['col'],
  components: {BackgroundCard},
  data() {
    return {
      backgrounds: [],
      randomBackground: true
    }
  },
  mounted() {
    this.getUserBackgrounds()

    EventBus.$on('changeBg', () => {
      this.getUserBackgrounds()
    })

    EventBus.$on('deleteBackground', () => {
      this.getUserBackgrounds()
    })
    // 设置container高度
    let innerHeight = window.innerHeight;
    // document.querySelector('.waterfall-container')
    this.$refs.container.style.height = innerHeight - 61 - 20 - 20 - 160 + 'px';
  },
  methods: {
    getUserBackgrounds() {
      axios.get('/api/user/background', {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        this.backgrounds = res.data.data.backgrounds
        this.randomBackground = res.data.data.randomBackground
      })
    },
    changeBackgroundStatus() {
      axios.post('/api/user/background/random', {
        randomBackground: this.randomBackground
      }, {
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token')
        }
      }).then(res => {
        if (res.data.code === 200) {
          this.$notify.success({
            title: '操作成功',
            message: '背景设置已更新'
          })
        } else {
          this.$notify.error({
            title: '操作失败',
            message: '遇到了某些错误'
          })
          setTimeout(() => {
            this.randomBackground = !this.randomBackground
          }, 1000)
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <span>背景设置：</span>
    <el-switch v-model="randomBackground" active-text="随机背景" inactive-text="收藏的背景"
               @change="changeBackgroundStatus"></el-switch>
    <el-divider></el-divider>
    <div class="waterfall-container" style="overflow: scroll" ref="container">
      <Waterfall
          :resetSign="true"
          :pageData="backgrounds"
          :column-count="col"
          query-sign="#cardItem">
        <template #default="{ item }">
          <BackgroundCard :background="item" id="cardItem"></BackgroundCard>
        </template>
      </Waterfall>
    </div>
  </div>
</template>

<style scoped>

</style>