<script>
import {EventBus} from "@/EventBus";

export default {
  name: 'GameCard',
  props: ['game', 'level'],
  data() {
    return {
      colors: ['#99A9BF', '#F7BA2A', '#FF9900'],
      info: {
        cover: '',
        type: ''
      },
      imageList: [],
      title: '',
      dialogWidth: '30%'
    }
  },
  mounted() {
    this.info = this.game
    if (this.level === 1) {
      this.dialogWidth = '90%'
    } else if (this.level === 2) {
      this.dialogWidth = '70%'
    } else if (this.level === 3) {
      this.dialogWidth = '50%'
    } else if (this.level === 4) {
      this.dialogWidth = '50%'
    } else {
      this.dialogWidth = '30%'
    }
  },
  methods: {
    setCarouselHeight() {
      let width = document.querySelector('.img').clientWidth;
      document.querySelector('.el-carousel__container').style.height = width * 3 / 4 + 'px';
    },
    gameInfo() {
      // this.$router.push({path: `/game/${this.info.id}`})
    },
    showImage() {
      EventBus.$emit('showImage', this.game.sample)
    }
  }
}
</script>

<template>
  <div>
    <el-card :body-style="{padding: '5px'}" shadow="hover" class="card">
      <div class="image-container" @click="showImage">
        <el-image
            :src="info.cover"
            lazy
            style="width: 100%; aspect-ratio: 4 / 3"
        >
          <div slot="error" class="image-slot">
            <i class="el-icon-picture-outline"></i>
          </div>
          <div slot="placeholder" class="image-slot">
            <i class="el-icon-loading"></i>
          </div>
        </el-image>
      </div>
      <div style="margin-top: 5px;display: flex; align-items: center;">
        <el-tag size="small">{{ info.id }}</el-tag>
        <el-tag size="small">{{ info.type.split('/')[0] }}</el-tag>
        <el-tag size="small" type="success" effect="plain" v-if="!info.deleted">
          <a :href="info.dlsiteUrl" target="_blank" class="dlsiteId">
            <img src="/dlsite.ico" alt="dlsite" height="16" width="16"/>&nbsp;{{ info.gameId }}
          </a>
        </el-tag>
        <el-tag size="small" type="danger" v-else>
          <font-awesome-icon :icon="['fas', 'link-slash']"/>&nbsp;{{ info.gameId }}
        </el-tag>
      </div>
      <el-tooltip effect="dark" :content="info.name" placement="top-start" style="margin-top: 5px" class="title">
        <a :href="info.dlsiteUrl" target="_blank" class="name">{{ info.name }}</a>
      </el-tooltip>
      <div style="margin-top: 3px">
        <div class="author" v-if="info.dlsiteMakerUrl">
          <a :href="info.dlsiteMakerUrl" target="_blank">{{ info.author }}</a>
        </div>
        <div class="author" v-else>{{ info.author }}</div>
      </div>
      <div style="margin-top: 3px">
        <el-rate v-model="info.score"
                 disabled
                 show-score
                 :colors="colors">
        </el-rate>
      </div>
    </el-card>
  </div>
</template>

<style scoped>
.title {
  color: #7B66FF;
  cursor: pointer;
  width: 100%;
}

.name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  width: 100%; /* Ensure the element has a fixed width */
}

.author a {
  color: #5FBDFF;
}

.author {
  font-size: 12px;
}

.el-tag {
  margin-right: 5px;
}

.el-rate {
  width: 150px;
}

a {
  text-decoration: none;
}

.card {
  border-radius: 10px;
  margin-bottom: 5px;
}

.el-dialog-div {
  overflow: auto;
  height: 570px;
}

.dlsiteId {
  display: flex;
  align-items: center;
  color: #409EFF;
}


</style>
